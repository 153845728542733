<template>
    <div>
        <custom-button :class="additionalButtonClasses" v-if="isButton" :colourType="buttonType" @click.native="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </custom-button>
        <a v-else class="cursor-pointer font-normal  text-gray-400"  @click="displayModal = true" :data-cy="`action-button-${action.name}`">
            {{title ? title : action.title}}
        </a>
        <portal to="destination">
        <large-model v-show="displayModal" :submitting="submittingModal" :title="action.title" :data-cy="`action-button-${action.name}`"
        :confirmationString='`Are you sure you want to \"${action.title}\"`'
        @removeFromDisplay="displayModal = false" @perform="perform()">
            <div class="grid grid-cols-2 gap-2">
                <div class="grid grid-cols-3 auto-rows-min">
                    <collapsable-header class="col-span-3" :collapsable="false" title="Project Information">
                    </collapsable-header>
                    <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'name'|| field.name === 'address' || field.name === 'type_id'|| field.name === 'manufacturer_id' || field.name === 'tender' || field.name === 'sage_reference' || field.name === 'is_tender') )">
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            {{ field[1].title }}
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                        </div>
                        <div class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                :fieldId="field[0]"
                                :show-label="false"
                                v-model="formData[field[0]]">
                            </property-or-field>
                            <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                        </div>
                    </template>
                </div>
                <div class="grid grid-cols-3 auto-rows-min">
                    <collapsable-header class="col-span-3" :collapsable="false" title="Project Information">
                    </collapsable-header>
                    <template v-for="field in action.fields.filter(field => field.type !== 'hidden' && (field.name === 'latitude' ||field.name === 'weekday_start' || field.name === 'weekday_end' || field.name === 'weekend_start' || field.name === 'weekend_end' || field.name === 'deduct_lunch' || field.name === 'delivery_notifications' ||field.name === 'site_rules' ) )">
                        <div v-if="field[0] !=='latitude'" class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            {{ field[1].title }}
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                        </div>
                        <div v-else class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Location
                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>
                        </div>

                        <div v-if="field[0] !=='latitude'" class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                :fieldId="field[0]"
                                :show-label="false"
                                v-model="formData[field[0]]">
                            </property-or-field>
                            <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                        </div>
                        <div v-else class="col-span-2 whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <div class="flex justify-between">
                                <property-or-field class="mr-1"
                                    :action="action"
                                    :fieldId="field[0]"
                                    :show-label="false"
                                    v-model="latitude">
                                </property-or-field>

                                <property-or-field class="mx-1"
                                    :action="action"
                                    :fieldId="'longitude'"
                                    :show-label="false"
                                    v-model="longitude">
                                </property-or-field>
                                <location-picker-with-icon :x="latitude" :y="longitude" :usePortal="true" @sendLocation="saveLocation" class="items-center">
                                    <template v-slot:icon>
                                        <svg class="w-5 h-5 cursor-pointer text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4s4-1.79 4-4s-1.79-4-4-4m8.94 3A8.994 8.994 0 0 0 13 3.06V1h-2v2.06A8.994 8.994 0 0 0 3.06 11H1v2h2.06A8.994 8.994 0 0 0 11 20.94V23h2v-2.06A8.994 8.994 0 0 0 20.94 13H23v-2zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7s7 3.13 7 7s-3.13 7-7 7"/></svg>
                                    </template>
                                </location-picker-with-icon>

                            </div>
                            <div v-show="errors.has(field[0])" class="text-v3-red text-sm">{{ errors.first(field[0]) }}</div>
                        </div>
                    </template>
                </div>
                <div class="grid grid-cols-4 auto-rows-min">
                    <collapsable-header class="col-span-4" :collapsable="false" title="Departments">
                        <template v-slot:title>
                            <div class="flex justify-between">
                                <span>
                                    Department
                                </span>

                                <custom-button @click.native="addNewItem">
                                    Add Department
                                </custom-button>
                            </div>
                        </template>
                    </collapsable-header>

                    <div v-if="departments.length === 0" class="col-span-4 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                        <span class="flex justify-center">No department codes added</span>
                    </div>

                    <template v-else v-for="(department,key) in departments">
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Department
<!--                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>-->
                        </div>
                        <div class=" whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="department_id"
                                :show-label="false"
                                v-model="department.department">
                            </property-or-field>
                        </div>
                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">
                            Code
                            <!--                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>-->
                        </div>
                        <div class=" whitespace-normal px-3 py-2 text-sm text-left w-full">
                            <property-or-field
                                :action="action"
                                fieldId="department_codes"
                                :show-label="false"
                                v-model="department.code">
                            </property-or-field>
                        </div>
                    </template>
                </div>

<!--                <div class="grid grid-cols-4 auto-rows-min">-->
<!--                    <collapsable-header class="col-span-4" :collapsable="false" title="Specifications">-->
<!--                        <template v-slot:title>-->
<!--                            <div class="flex justify-between">-->
<!--                                <span>-->
<!--                                    Specifications-->
<!--                                </span>-->

<!--                                <custom-button @click.native="addNewSpecification">-->
<!--                                    Add Specification-->
<!--                                </custom-button>-->
<!--                            </div>-->
<!--                        </template>-->
<!--                    </collapsable-header>-->

<!--                    <div v-if="specifications.length === 0" class="col-span-4 whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">-->
<!--                        <span class="flex justify-center">No specifications added</span>-->
<!--                    </div>-->

<!--                    <template v-else v-for="(specification,key) in specifications">-->
<!--                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">-->
<!--                            Type-->
<!--                            &lt;!&ndash;                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class=" whitespace-normal px-3 py-2 text-sm text-left w-full">-->
<!--                            <property-or-field-->
<!--                                :action="action"-->
<!--                                fieldId="type_id"-->
<!--                                :show-label="false"-->
<!--                                v-model="specification.type_id">-->
<!--                            </property-or-field>-->
<!--                        </div>-->
<!--                        <div class="whitespace-normal py-2 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left w-full text-v3-gray-900 text-opacity-40 dark:text-white dark:text-opacity-40">-->
<!--                            Manufacturer-->
<!--                            &lt;!&ndash;                            <span class="text-v3-gray-900 text-opacity-20 dark:text-white dark:text-opacity-20" v-if="isActionRequired('name')">*</span>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class=" whitespace-normal px-3 py-2 text-sm text-left w-full">-->
<!--                            <property-or-field-->
<!--                                :action="action"-->
<!--                                fieldId="manufacturer_id"-->
<!--                                :show-label="false"-->
<!--                                v-model="specification.manufacturer_id">-->
<!--                            </property-or-field>-->
<!--                        </div>-->
<!--                    </template>-->
<!--                </div>-->
            </div>
        </large-model>
        </portal>

    </div>
</template>

<script>
    import CustomButton from "@/v3/Buttons/CustomButton.vue";
    import LargeModel from "@/v3/models/LargeModel.vue";
    import fileDownload from "js-file-download";
    import LocationPickerWithIcon from "@/components/LocationPickerWithIcon";
    import DepartmentCodeInput from "@/components/Action/Fields/DepartmentCodeInput.vue";
    import PropertyOrField from "@/components/PropertyOrField.vue";
    import CollapsableHeader from "@/v3/components/headers/CollapsableHeader.vue";

    export default {
        data() {
            return {
                displayModal: false,
                submittingModal: false,
                latitude: null,
                longitude: null,
                openLocation: false,
                departments: [],
                specifications: []
            }
        },
        components: {
            CollapsableHeader,
            PropertyOrField,
            DepartmentCodeInput,
            LocationPickerWithIcon,
            CustomButton,
            LargeModel
        },
        props: {
            action: {
                type: Object,
            },
            actionKey: {
                type: String
            },
            onSuccessPath: {
                type: String
            },
            redirectOnSuccess: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: null
            },
            isButton:{
                type: Boolean,
                default: true
            },
            buttonType:{
                type: String,
                default: 'primary'
            },
            additionalButtonClasses:{
                type: String,
                default: null
            }
        },
        computed: {
            formData() {
                if (!this.action.fields) {
                    return {};
                }
                var payload = {};
                let that = this;
                this.action.fields.forEach(function (field) {
                    if (field.value !== undefined) payload[field.name] = field.value;
                    if(field.value !== undefined && field.name === 'latitude') this.latitude = field.value;
                    if(field.value !== undefined && field.name === 'longitude') this.longitude = field.value;
                }.bind(this));
                return payload;
            },
        },
        methods: {
            getFieldComponent(field) {
                if(field.title.includes("(GBP)")){
                    return 'gbp-input';
                }

                return field.type + '-input';
            },
            perform() {
                this.submittingModal = true;
                this.formData['latitude'] = this.latitude;
                this.formData['longitude'] = this.longitude;
                this.formData['department_codes'] = JSON.stringify(this.departments);
                this.formData['specifications'] = JSON.stringify(this.specifications);

                this.action.perform(this.formData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.displayModal = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    if (this.redirectOnSuccess) {
                        let newPath = this.onSuccessPath;
                        let fullPath = this.$router.currentRoute.fullPath;

                        if(newPath  !== fullPath) {
                            this.$router.push(this.onSuccessPath ? this.onSuccessPath : '/orders');
                        }else{
                            this.$router.go(0);
                        }
                    }
                    this.$emit('success', res);
                }).catch(error => {
                    this.submittingModal = false;
                    if(error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    }else{
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            },
            messages(response) {
                return response.body.entities
                    .filter(function (entity) {
                        return entity.rels.contains("status-messages");
                    })
                    .first();
            },
            saveLocation(location){
                this.latitude = location.x;
                this.longitude = location.y;
                this.formData['latitude'] = location.x;
                this.formData['longitude'] = location.y;
            },
            isActionRequired(fieldId){
                if (!this.action) {
                    return false;
                }
                let field = this.action.fields.filter(field => field.name === fieldId).first();
                if(field){
                    if(field.validation != null) {
                        return field.validation.includes('required')
                    }
                }
                return false;
            },
            addNewItem() {
                this.departments.push({department:'', code: ''});
                this.$emit('change');
            },
            removeItem(key) {
                this.$set(this.departments, this.departments.splice(key, 1));
                this.$emit('change');
            },
            addNewSpecification(){
                this.specifications.push({type_id: '', manufacturer_id: ''});
                this.$emit('change');
            },
            removeSpecification(key){
                this.$set(this.specifications, this.specifications.splice(key, 1));
                this.$emit('change');
            }
        },

    }
</script>

<style lang="scss" scoped>

</style>
